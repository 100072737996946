<template>
  <div>
    <BaseHeaderB></BaseHeaderB>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      top
      :color="snackcolor"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container class="header">
      <div class="content">
        <h2 style="font-weight: 300; font-size: 26px; margin-left: -5px">
          Welcome {{ this.auth.User.FirstName }},
        </h2>
        <p style="padding: 1% 0%">Level: {{ this.auth.User.Level }}</p>
        <h2
          class="mx-auto"
          style="text-align: center"
          v-if="auth.User.isPremium === false"
        >
          Become a Fixxer today
        </h2>
      </div>
    </v-container>
    <div class="plans" v-if="auth.User.isPremium === true">
      <h2 class="mx-auto">Welcome to Fixxer Upper</h2>
      <p>
        You now get access to the following features. If you would like to
        cancel your subscription please contact us on fixxerupperuk@gmail.com
      </p>
      <v-list>
        <v-list-item-group v-model="model">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            style="padding: 0% !important"
          >
            <v-list-item-icon>
              <v-icon style="color: #14213d" light right>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <div class="plans" v-if="auth.User.isPremium === false">
      <v-card class="myCards mx-auto pa-2">
        <h2 class="mx-auto">Newbie Fixxer</h2>
        <h2 class="mx-auto" style="font-weight: 300">
          Start your 14 day free trial now.
        </h2>
        <p>14 day free trial, then £9.99 every month.</p>
        <v-card-text>
          <v-list>
            <v-list-item-group v-model="model">
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                style="padding: 0% !important"
              >
                <v-list-item-icon>
                  <v-icon style="color: #14213d" light right>
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <BasePayment
            :price="'price_1LDoTVDNzzRQ2afTZ4IvrNDm'"
            :quantity="1"
          ></BasePayment>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BasePayment from "../components/BasePayment.vue";
export default {
  data: () => ({
    upgrade: true,
    e1: 1,
    multiLine: true,
    snackbar: false,
    snackcolor: "red accent-3",
    text: "",
    items: [
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Find Fixxer-Uppers in your area",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Search and compare properties",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Identify 10 common defects",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Unlimted property searches",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Unlimited defect reports",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Early access to future features",
      },
    ],
  }),
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    let getSessionID = this.$route.query.session_id;
    if (getSessionID === "failure") {
      this.text =
        "Oops! Something went wrong with your payment, please try again.";
      this.snackbar = true;
      this.snackcolor = "red accent-3";
    } else if (getSessionID.includes("cs_live_")) {
      this.text = "Congratulations, your payment was successful";
      this.snackbar = true;
      this.snackcolor = "teal accent-4";
      const uid = sessionStorage.getItem("userid");
      let payload = { uid: uid, sessionID: getSessionID };
      this.$store.dispatch("subscriptionActive", payload);
    } else {
      //do nothing
    }
  },
  components: { BasePayment },
};
</script>

<style>
.upgrade {
  color: white;
  font-weight: bolder;
  font-size: 31px;
  padding: 35px 15px;
  text-align: left;
}
.header {
  margin: 0%;
  max-width: 100%;
}
.content {
  background-color: #14213d;
  background-size: cover;
  height: 250px;
  padding: 2%;
  color: white;
  margin: -12px;
}
.plans {
  background-color: white;
  padding: 2%;
}

@media screen and (max-width: 600px) {
  .myCards {
    margin-top: -25%;
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .myCards {
    margin-top: -12%;
    width: 45%;
  }
  @media only screen and (min-width: 992px) {
    .myCards {
      margin-top: -5%;
      width: 30%;
    }
  }
}
</style>
